import { useEffect } from 'react';
import { auth, db } from '../firebase';
import { query, collection, where, onSnapshot, updateDoc } from 'firebase/firestore';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useLocation } from 'react-router-dom';

// Function to mark all notifications as read
const markAllNotificationsAsRead = () => {
  const user = auth.currentUser;
  if (user) {
    const notificationsRef = collection(db, 'notifications');
    const userNotificationsQuery = query(
      notificationsRef,
      where('userId', '==', user.uid),
      where('read', '==', false)
    );

    onSnapshot(userNotificationsQuery, (snapshot) => {
      snapshot.docs.forEach((doc) => {
        const notificationRef = doc.ref;
        updateDoc(notificationRef, {
          read: true
        }).catch((error) => {
          console.error('Error marking notification as read:', error);
        });
      });
    });
  }
};

// Function to subscribe to notifications
export const subscribeToNotifications = (callback) => {
  const user = auth.currentUser;
  if (user) {
    const notificationsRef = collection(db, 'notifications');
    const userNotificationsQuery = query(
      notificationsRef,
      where('userId', '==', user.uid),
      where('read', '==', false)
    );

    const unsubscribe = onSnapshot(userNotificationsQuery, (snapshot) => {
      console.log('Notifications snapshot: ', snapshot.size);
      callback(snapshot.size > 0);
    });

    return unsubscribe;
  }
  console.error('No user is currently authenticated for notifications subscription');
  return null;
};

// NotificationIcon component
const NotificationIcon = ({ hasNewNotifications }) => {
  const location = useLocation();
  const currentPage = location.pathname;

  useEffect(() => {
    if (currentPage === '/notifications') {
      markAllNotificationsAsRead();
    }
  }, [currentPage]);

  return (
    <Badge color="primary" variant="dot" invisible={!hasNewNotifications}>
      <NotificationsIcon />
    </Badge>
  );
};

export default NotificationIcon;