import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Box,
  Typography,
  List,
  ListItem,
  CircularProgress,
  IconButton,
  Button,
  Paper,
  Avatar,
  styled,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  updateDoc,
  doc,
  orderBy,
  limit,
  onSnapshot
} from "firebase/firestore";
import BottomNav from "./BottomNav";
import Atras from "./atras";
import { 
  Refresh as RefreshIcon,
  Chat as ChatIcon,
  Work as WorkIcon,
  Description as DescriptionIcon,
  Notifications as NotificationsIcon 
} from "@mui/icons-material";

// Estilos personalizados
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: theme.shadows[1],
}));

const NotificationPaper = styled(Paper)(({ theme, read }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  transition: 'all 0.3s ease',
  backgroundColor: read ? theme.palette.background.paper : theme.palette.primary.light,
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
    cursor: 'pointer',
  },
}));

const NotificationAvatar = styled(Avatar)(({ theme, notificationType }) => {
  const colors = {
    chat: theme.palette.primary.main,
    project_skill: theme.palette.success.main,
    new_proposal: theme.palette.secondary.main,
  };

  return {
    backgroundColor: colors[notificationType] || theme.palette.grey[500],
  };
});

const LoadMoreButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const EmptyStateBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  height: '50vh',
}));

const NOTIFICATIONS_PER_PAGE = 10;

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const fetchNotifications = useCallback(async () => {
    let unsubscribe = null;
    try {
      const user = await isLoggedIn();
      if (!user) {
        navigate("/login");
        return null;
      }

      const uid = user.uid;

      const notificationsRef = collection(db, "notifications");
      const notificationsQuery = query(
        notificationsRef,
        where("userId", "==", uid),
        orderBy("timestamp", "desc"),
        limit(page * NOTIFICATIONS_PER_PAGE)
      );

      unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
        try {
          const notificationsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          console.log("Real-time notifications update:", notificationsData);
          setNotifications(notificationsData);
          setError(null);
        } catch (error) {
          console.error("Error processing notifications update:", error);
          setError("Failed to process notifications update. Please try again.");
        } finally {
          setLoading(false);
        }
      }, (error) => {
        console.error("Error in notifications listener:", error);
        setError("Failed to listen for notifications. Please try again.");
        setLoading(false);
      });

    } catch (err) {
      console.error("Error fetching notifications:", err);
      setError("Failed to load notifications. Please try again.");
      setLoading(false);
    }

    return unsubscribe;
  }, [navigate, page]);

  useEffect(() => {
    let unsubscribe = null;

    const setupNotifications = async () => {
      unsubscribe = await fetchNotifications();
    };

    setupNotifications();

    return () => {
      if (unsubscribe && typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [fetchNotifications]);

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'chat':
        return <ChatIcon />;
      case 'project_skill':
        return <WorkIcon />;
      case 'new_proposal':
        return <DescriptionIcon />;
      default:
        return <NotificationsIcon />;
    }
  };

  const getNotificationColor = (type) => {
    switch (type) {
      case 'chat':
        return 'primary';
      case 'project_skill':
        return 'success';
      case 'new_proposal':
        return 'secondary';
      default:
        return 'default';
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      const notificationRef = doc(db, "notifications", notification.id);
      await updateDoc(notificationRef, { read: true });

      switch (notification.type) {
        case "chat":
          navigate(`/chatbox/${notification.projectId}/${notification.proposalId}/${notification.userId || ""}/${notification.projectUid}`);
          break;
        case "project_skill":
          navigate(`/project/${notification.projectId}`);
          break;
        case "new_proposal":
          navigate(`/project/${notification.projectId}/proposals/${notification.proposalId}`);
          break;
        default:
          console.log("Unknown notification type:", notification.type);
      }
    } catch (error) {
      console.error("Error updating notification:", error);
      setError("Failed to update notification. Please try again.");
    }
  };

  const renderNotificationContent = (notification) => {
    const color = getNotificationColor(notification.type);
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
        <NotificationAvatar notificationType={notification.type}>
          {getNotificationIcon(notification.type)}
        </NotificationAvatar>
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="subtitle1"
            color={`${color}.main`}
            sx={{ fontWeight: 600 }}
          >
            {notification.type === 'chat' && 'Nuevo mensaje de chat'}
            {notification.type === 'project_skill' && 'Nuevo proyecto coincidente'}
            {notification.type === 'new_proposal' && 'Nueva propuesta recibida'}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            {notification.message}
          </Typography>
          <Typography variant="caption" color="text.secondary" sx={{ mt: 1, display: 'block' }}>
            {notification.timestamp?.toDate().toLocaleString()}
          </Typography>
        </Box>
      </Box>
    );
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="sm">
      <StyledAppBar position="fixed">
        <Toolbar>
          <Atras color="inherit" />
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Notificaciones
          </Typography>
          <IconButton color="inherit" onClick={() => {
            setLoading(true);
            setPage(1);
            fetchNotifications();
          }}>
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </StyledAppBar>

      <Box sx={{ mt: 10, mb: 10 }}>
        {error && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        
        
        {notifications.length > 0 ? (
          <>
            <List sx={{ width: '100%' }}>
              {notifications.map((notification) => (
                <ListItem 
                  key={notification.id} 
                  disablePadding 
                  sx={{ mb: 2 }}
                >
                  <NotificationPaper
                    read={notification.read}
                    onClick={() => handleNotificationClick(notification)}
                    elevation={notification.read ? 1 : 2}
                  >
                    {renderNotificationContent(notification)}
                  </NotificationPaper>
                </ListItem>
              ))}
            </List>
            
            {notifications.length % NOTIFICATIONS_PER_PAGE === 0 && (
              <LoadMoreButton
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => setPage(prev => prev + 1)}
              >
                Cargar más
              </LoadMoreButton>
            )}
          </>
        ) : (
          <EmptyStateBox>
            <NotificationsIcon sx={{ fontSize: 60, color: 'text.secondary', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No tienes notificaciones nuevas
            </Typography>
          </EmptyStateBox>
        )}
      </Box>

      <BottomNav />
    </Container>
  );
};

export default Notifications;