import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase";
import {
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  where,
  orderBy,
  limit
} from "firebase/firestore";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Container,
  Badge,
  CircularProgress,
} from "@mui/material";
import { isLoggedIn } from "../session";
import BottomNav from "./BottomNav";
import Atras from "./atras";
import { format } from "date-fns";

function Chats() {
  const [chats, setChats] = useState([]);
  const [authUserId, setAuthUserId] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchAuthUserId = useCallback(async () => {
    try {
      const user = await isLoggedIn();
      if (user) {
        setAuthUserId(user.uid);
        console.log("Authenticated User ID:", user.uid);
      } else {
        console.log("No authenticated user found.");
        navigate("/login");
      }
    } catch (error) {
      console.error("Error in fetchAuthUserId:", error);
      setError("Failed to authenticate user.");
    }
  }, [navigate]);

  useEffect(() => {
    fetchAuthUserId();
  }, [fetchAuthUserId]);

  const fetchChats = useCallback(async () => {
    if (!authUserId) return;

    setLoading(true);
    setError(null);

    try {
      console.log("Fetching chats for user:", authUserId);

      const projectsRef = collection(db, "projects");
      const projectsQuery = query(projectsRef, where("uid", "==", authUserId));
      const projectsSnapshot = await getDocs(projectsQuery);

      const proposalsRef = collection(db, "proposals");
      const proposalsQuery = query(proposalsRef, where("freelanceUid", "==", authUserId));
      const proposalsSnapshot = await getDocs(proposalsQuery);

      if (projectsSnapshot.empty && proposalsSnapshot.empty) {
        console.log("No projects or proposals found for the user.");
        setChats([]);
        setLoading(false);
        return;
      }

      const chatPromises = [
        ...projectsSnapshot.docs.map((projectDoc) => processProject(projectDoc, authUserId, true)),
        ...proposalsSnapshot.docs.map((proposalDoc) => processProposal(proposalDoc, authUserId, false)),
      ];

      const chatData = (await Promise.all(chatPromises)).filter((chat) => chat !== null);
      console.log("Final chat data:", chatData);

      setChats(chatData);
    } catch (error) {
      console.error("Error fetching chats:", error);
      setError("Failed to load chats. Please try again later.");
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUserId]);

  useEffect(() => {
    if (authUserId) {
      fetchChats();
    }
  }, [authUserId, fetchChats]);

  const processProject = async (projectDoc, authUserId, isProjectOwner) => {
    const projectId = projectDoc.id;
    const projectData = projectDoc.data();
    console.log("Processing project:", projectId, projectData);

    const proposalsQuery = query(collection(db, "projects", projectId, "proposals"));
    const proposalsSnapshot = await getDocs(proposalsQuery);
    console.log(`Proposals found for project ${projectId}:`, proposalsSnapshot.size);

    const proposalDoc = proposalsSnapshot.docs[0];
    if (!proposalDoc) {
      console.log(`No proposals found for project ${projectId}`);
      return null;
    }

    return processChat(projectId, proposalDoc, projectData, authUserId, isProjectOwner);
  };

  const processProposal = async (proposalDoc, authUserId, isProjectOwner) => {
    const proposalData = proposalDoc.data();
    const projectId = proposalData.projectId;
    console.log("Processing proposal for project:", projectId, proposalData);

    const projectDoc = await getDoc(doc(db, "projects", projectId));
    if (!projectDoc.exists()) {
      console.log(`Project ${projectId} not found for proposal ${proposalDoc.id}`);
      return null;
    }

    const projectData = projectDoc.data();
    return processChat(projectId, proposalDoc, projectData, authUserId, isProjectOwner);
  };

  const processChat = async (projectId, proposalDoc, projectData, authUserId, isProjectOwner) => {
    const proposalId = proposalDoc.id;
    const proposalData = proposalDoc.data();

    console.log(`Proposal data for project ${projectId}:`, proposalData);

    const freelancerUid = isProjectOwner ? proposalData.freelanceUid : authUserId;
    const projectOwnerUid = isProjectOwner ? authUserId : projectData.uid;

    if (!freelancerUid || !projectOwnerUid) {
      console.error(`Missing user UID for project ${projectId}. Proposal data:`, proposalData);
      return null;
    }

    const messagesCollectionRef = collection(db, "projects", projectId, "proposals", proposalId, "messages");
    const lastMessageQuery = query(messagesCollectionRef, orderBy("timestamp", "desc"), limit(1));
    const lastMessageSnapshot = await getDocs(lastMessageQuery);

    let lastMessage = {
      content: "No hay mensajes",
      timestamp: null,
      senderId: null,
    };

    let unreadCount = 0;

    if (!lastMessageSnapshot.empty) {
      lastMessage = lastMessageSnapshot.docs[0].data();

      const unreadQuery = query(
        messagesCollectionRef,
        where("read", "==", false),
        where("senderId", "!=", authUserId)
      );
      const unreadSnapshot = await getDocs(unreadQuery);
      unreadCount = unreadSnapshot.size;
    }

    const otherUserUid = isProjectOwner ? freelancerUid : projectOwnerUid;
    const otherUserDoc = await getDoc(doc(db, "users", otherUserUid));
    const otherUserData = otherUserDoc.data();
    const otherUserDisplayName = otherUserData?.displayName || "Unknown User";
    const otherUserAvatar = otherUserData?.photoURL || otherUserData?.profileImageUrl || "";

    console.log("Other user data:", {
      uid: otherUserUid,
      displayName: otherUserDisplayName,
      avatar: otherUserAvatar,
    });

    console.log("Chat data prepared for project:", projectId);

    return {
      id: projectId,
      title: projectData.title,
      imageUrl: otherUserAvatar,
      lastMessage,
      unreadCount,
      projectUid: projectOwnerUid,
      freelancerUid: freelancerUid,
      otherUserDisplayName: otherUserDisplayName,
      proposalId: proposalId,
      lastMessageTime: lastMessage?.timestamp ? lastMessage.timestamp.toDate() : null,
      isProjectOwner: isProjectOwner,
    };
  };

  const handleChatClick = (projectId, freelancerUid, proposalId) => {
    navigate(`/chatbox/${projectId}/${proposalId}/${freelancerUid}/${authUserId}`);
  };

  return (
    <Box sx={{ mt: 10, pb: 7 }}>
      <AppBar position="fixed">
        <Toolbar sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          backgroundColor: "dodgerblue",
        }}>
          <Atras />
          <Typography variant="h6" sx={{
            paddingLeft: "24px",
            paddingTop: "10px",
            fontSize: "24px",
            color: "white",
          }} gutterBottom>
            Chats
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 3 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" sx={{ mt: 5, textAlign: "center" }}>
            {error}
          </Typography>
        ) : chats.length > 0 ? (
          <List>
            {chats.map((chat) => (
              <React.Fragment key={chat.id}>
                <ListItem button onClick={() => handleChatClick(chat.id, chat.isProjectOwner ? chat.freelancerUid : chat.projectUid, chat.proposalId)}>
                  <Badge color="error" badgeContent={chat.unreadCount} invisible={chat.unreadCount === 0}>
                    <Avatar src={chat.imageUrl} alt={chat.otherUserDisplayName} sx={{
                      border: "2.5px solid slateblue",
                      padding: "0px",
                      marginRight: "15px",
                    }} />
                  </Badge>
                  <ListItemText
                    primary={chat.title}
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="textPrimary">
                          {chat.isProjectOwner ? "Freelancer" : "Dueño del proyecto"}: {chat.otherUserDisplayName}:{" "}
                        </Typography>
                        Ultimo mensaje: {chat.lastMessage.content}
                      </>
                    }
                  />
                  <Typography variant="caption" sx={{ marginLeft: "auto" }}>
                    {chat.lastMessageTime ? format(chat.lastMessageTime, "p") : ""}
                  </Typography>
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        ) : (
          <Typography variant="body1" sx={{ mt: 5, textAlign: "center" }}>
            No hay chats disponibles.
          </Typography>
        )}
      </Container>
      <BottomNav />
    </Box>
  );
}

export default Chats;