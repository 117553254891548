import React, { createContext, useState, useContext, useEffect } from "react";
import { onSnapshot, doc } from "firebase/firestore";
import { auth, db } from "../firebase";

// Create the context with a default value that matches the shape of the context
const SubscriptionContext = createContext({
  subscription: null,
  loading: true,
  setSubscription: () => {},
});

// Custom hook to use the subscription context
export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error("useSubscriptionContext must be used within a SubscriptionProvider");
  }
  return context;
};

// Provider component
export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set up auth state listener
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (!user) {
        setSubscription(null);
        setLoading(false);
        return;
      }

      // Set up subscription listener
      const unsubscribeSnapshot = onSnapshot(doc(db, "users", user.uid), (doc) => {
        if (doc.exists()) {
          setSubscription(doc.data().subscription || null);
        } else {
          setSubscription(null);
        }
        setLoading(false);
      });

      return () => unsubscribeSnapshot();
    });

    return () => unsubscribeAuth();
  }, []);

  const value = {
    subscription,
    loading,
    setSubscription,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};