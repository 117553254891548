import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  Typography,
  List,
  ListItem,
  CircularProgress,
  Grid,
  TextField,
  Box,
  Chip,
  OutlinedInput,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { db } from "../firebase";
import { collection, getDocs, where, query } from "firebase/firestore";
import logoSvg from "../imgs/logo.svg";
import BottomToolbar from "./BottomNav";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Members = () => {
  const [freelancers, setFreelancers] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allSkills, setAllSkills] = useState([]);

  const navigate = useNavigate();

  const filterFreelancers = useCallback(() => {
    const nameSearchLower = nameSearch.toLowerCase();
    const filteredFreelancers = freelancers.filter(
      (freelancer) =>
        freelancer.displayName.toLowerCase().includes(nameSearchLower) &&
        (selectedSkills.length === 0 || 
         selectedSkills.every(skill => freelancer.skills.includes(skill)))
    );
    setFilteredResults(filteredFreelancers);
  }, [nameSearch, selectedSkills, freelancers]);

  useEffect(() => {
    filterFreelancers();
  }, [filterFreelancers]);

  useEffect(() => {
    const fetchFreelancers = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          setLoading(true);
          const freelancersCollection = collection(db, "users");
          const q = query(
            freelancersCollection,
            where("userType", "==", "freelancer")
          );
          const freelancersSnapshot = await getDocs(q);
          const freelancersData = freelancersSnapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
          }));
          setFreelancers(freelancersData);
          setFilteredResults(freelancersData);

          // Extract all unique skills
          const skills = [...new Set(freelancersData.flatMap(f => f.skills))];
          setAllSkills(skills);

          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching freelancers:", error);
        setLoading(false);
      }
    };
    fetchFreelancers();
  }, []);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  const handleNameSearchChange = (event) => {
    setNameSearch(event.target.value);
  };

  const handleSkillChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSkills(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#fff" }}>
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <CardMedia
            component="img"
            image={logoSvg}
            title="Skach"
            sx={{ width: 160, marginBottom: 2 }}
          />
          <Typography variant="h6" sx={{ color: "black", fontSize: 12, fontWeight: "bold" }}>
            ¡Crea un proyecto o busca uno!
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 15, mb: 10 }}>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Buscar por nombre"
            variant="outlined"
            value={nameSearch}
            onChange={handleNameSearchChange}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth>
            <InputLabel id="skill-select-label">Seleccionar habilidades</InputLabel>
            <Select
              labelId="skill-select-label"
              multiple
              value={selectedSkills}
              onChange={handleSkillChange}
              input={<OutlinedInput label="Seleccionar habilidades" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {allSkills.map((skill) => (
                <MenuItem
                  key={skill}
                  value={skill}
                >
                  {skill}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {filteredResults.map((freelancer) => (
              <ListItem key={freelancer.key} sx={{ border: "1px solid #ccc", mb: 2, borderRadius: 2 }}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <CardMedia
                      component="img"
                      image={freelancer.profileImageUrl}
                      title="Perfil"
                      sx={{ width: 40, height: 40, borderRadius: "50%", border: "2.5px solid #312783" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body1" color="primary">
                      {freelancer.displayName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      📧 {freelancer.email}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      📞 {freelancer.phone}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      📍 {freelancer.point}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      ✔️ {freelancer.skills.join(", ")}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        )}
      </Container>
      <BottomToolbar />
    </Container>
  );
};

export default Members;