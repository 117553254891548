import { useEffect } from "react";
import { auth, db } from "../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  addDoc,
  serverTimestamp,
  getDoc,
  doc,
} from "firebase/firestore";

export const useListenForNewChatMessages = () => {
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is currently authenticated");
      return;
    }

    let unsubscribeChats;

    const setupListeners = async () => {
      try {
        // Obtener todos los proyectos y propuestas del usuario
        const projectsQuery = query(
          collection(db, "projects"),
          where("uid", "==", user.uid)
        );
        const projectsSnapshot = await getDocs(projectsQuery);

        const proposalsQuery = query(
          collection(db, "proposals"),
          where("freelanceUid", "==", user.uid)
        );
        const proposalsSnapshot = await getDocs(proposalsQuery);

        // Función para manejar nuevos mensajes
        const handleNewMessage = async (
          change,
          projectId,
          proposalId,
          isProjectOwner
        ) => {
          if (change.type === "added") {
            const newMessage = change.doc.data();

            // Verificar si el mensaje no es del usuario actual
            if (newMessage.senderId !== user.uid) {
              // Obtener el displayName del remitente
              const senderDoc = await getDoc(
                doc(db, "users", newMessage.senderId)
              );
              const senderName = senderDoc.exists()
                ? senderDoc.data().displayName
                : "Usuario desconocido";

              // Consultar si ya existe una notificación para este mensaje
              const notificationQuery = query(
                collection(db, "notifications"),
                where("userId", "==", user.uid),
                where("messageId", "==", change.doc.id),
                where("type", "==", "chat")
              );

              const querySnapshot = await getDocs(notificationQuery);
              if (querySnapshot.empty) {
                // Añadir nueva notificación si no existe
                try {
                  await addDoc(collection(db, "notifications"), {
                    userId: user.uid,
                    projectId: projectId,
                    proposalId: proposalId,
                    messageId: change.doc.id,
                    message: `Nuevo mensaje de ${senderName} en ${
                      isProjectOwner ? "tu proyecto" : "una propuesta"
                    }`,
                    type: "chat",
                    read: false,
                    timestamp: serverTimestamp(),
                    senderDisplayName: senderName, // Usamos displayName aquí
                  });
                  console.log("Chat notification added successfully");
                } catch (error) {
                  console.error("Error adding chat notification: ", error);
                }
              } else {
                console.log(
                  "Chat notification already exists for this message"
                );
              }
            }
          }
        };

        // Escuchar nuevos mensajes en proyectos del usuario
        projectsSnapshot.forEach((projectDoc) => {
          const projectId = projectDoc.id;
          const proposalsRef = collection(
            db,
            `projects/${projectId}/proposals`
          );

          getDocs(proposalsRef).then((proposalsSnapshot) => {
            proposalsSnapshot.forEach((proposalDoc) => {
              const proposalId = proposalDoc.id;
              const messagesRef = collection(
                db,
                `projects/${projectId}/proposals/${proposalId}/messages`
              );
              onSnapshot(messagesRef, (snapshot) => {
                snapshot
                  .docChanges()
                  .forEach((change) =>
                    handleNewMessage(change, projectId, proposalId, true)
                  );
              });
            });
          });
        });

        // Escuchar nuevos mensajes en propuestas del usuario
        proposalsSnapshot.forEach((proposalDoc) => {
          const proposalData = proposalDoc.data();
          const projectId = proposalData.projectId;
          const proposalId = proposalDoc.id;
          const messagesRef = collection(
            db,
            `projects/${projectId}/proposals/${proposalId}/messages`
          );
          onSnapshot(messagesRef, (snapshot) => {
            snapshot
              .docChanges()
              .forEach((change) =>
                handleNewMessage(change, projectId, proposalId, false)
              );
          });
        });
      } catch (error) {
        console.error("Error setting up chat listeners: ", error);
      }
    };

    setupListeners();

    // Cleanup function
    return () => {
      if (unsubscribeChats) unsubscribeChats();
    };
  }, []);
};

export const useListenForProjectSkills = () => {
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is currently authenticated");
      return;
    }

    const usersRef = collection(db, "users");
    const projectsRef = collection(db, "projects");
    const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000);
    const projectsQuery = query(
      projectsRef,
      where("createdAt", ">", fiveMinutesAgo)
    );

    const unsubscribeProjects = onSnapshot(projectsQuery, async (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added") {
          const project = change.doc.data();
          const projectId = change.doc.id;

          // Check if the project has skills defined and it's not an empty array
          if (
            !project.skills ||
            !Array.isArray(project.skills) ||
            project.skills.length === 0
          ) {
            console.log(
              `Project ${projectId} has no valid skills defined. Skipping notification creation.`
            );
            return;
          }

          try {
            // Create a query for users with matching skills
            const usersQuery = query(
              usersRef,
              where("skills", "array-contains-any", project.skills)
            );
            const usersSnapshot = await getDocs(usersQuery);

            usersSnapshot.forEach(async (userDoc) => {
              const userId = userDoc.id;
              const userSkills = userDoc.data().skills || [];

              // Verify matching skills
              const matchingSkills = userSkills.filter(skill => project.skills.includes(skill));

              if (matchingSkills.length > 0) {
                // Create a notification for this user
                const notificationsRef = collection(db, "notifications");
                const notificationQuery = query(
                  notificationsRef,
                  where("userId", "==", userId),
                  where("projectId", "==", projectId),
                  where("type", "==", "project_skill")
                );

                const querySnapshot = await getDocs(notificationQuery);
                if (querySnapshot.empty) {
                  try {
                    await addDoc(notificationsRef, {
                      userId: userId,
                      projectId: projectId,
                      message: `Nuevo proyecto que coincide con tus habilidades: ${matchingSkills.join(
                        ", "
                      )}`,
                      type: "project_skill",
                      read: false,
                      timestamp: serverTimestamp(),
                    });
                    console.log(
                      `Project skill notification added for user ${userId}`
                    );
                  } catch (error) {
                    console.error(
                      `Error adding project skill notification for user ${userId}:`,
                      error
                    );
                  }
                }
              }
            });
          } catch (error) {
            console.error(`Error processing project ${projectId}:`, error);
          }
        }
      });
    });

    return () => {
      if (unsubscribeProjects) unsubscribeProjects();
    };
  }, []);
};

export const useListenForNewProposals = () => {
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is currently authenticated");
      return;
    }

    const projectsRef = collection(db, "projects");
    const projectsQuery = query(projectsRef, where("uid", "==", user.uid));

    const unsubscribeProjects = onSnapshot(projectsQuery, (projectsSnapshot) => {
      projectsSnapshot.forEach((projectDoc) => {
        const projectId = projectDoc.id;
        const proposalsRef = collection(db, `projects/${projectId}/proposals`);
        
        const unsubscribeProposals = onSnapshot(proposalsRef, async (proposalsSnapshot) => {
          proposalsSnapshot.docChanges().forEach(async (change) => {
            if (change.type === "added") {
              const newProposal = change.doc.data();
              const proposalId = change.doc.id;

              // Check if a notification for this proposal already exists
              const notificationsRef = collection(db, "notifications");
              const notificationQuery = query(
                notificationsRef,
                where("userId", "==", user.uid),
                where("projectId", "==", projectId),
                where("proposalId", "==", proposalId),
                where("type", "==", "new_proposal")
              );

              const querySnapshot = await getDocs(notificationQuery);
              if (querySnapshot.empty) {
                // Create a new notification
                try {
                  const freelancerDoc = await getDoc(doc(db, "users", newProposal.freelanceUid));
                  const freelancerName = freelancerDoc.exists() ? freelancerDoc.data().displayName : "Un freelancer";

                  await addDoc(notificationsRef, {
                    userId: user.uid,
                    projectId: projectId,
                    proposalId: proposalId,
                    message: `${freelancerName} ha enviado una nueva propuesta para tu proyecto.`,
                    type: "new_proposal",
                    read: false,
                    timestamp: serverTimestamp(),
                  });
                  console.log(`New proposal notification added for project ${projectId}`);
                } catch (error) {
                  console.error(`Error adding new proposal notification for project ${projectId}:`, error);
                }
              }
            }
          });
        });

        return () => unsubscribeProposals();
      });
    });

    return () => {
      if (unsubscribeProjects) unsubscribeProjects();
    };
  }, []);
};
