import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

const PLAN_LIMITS = {
  Basic: { proposals: 3 },
  Professional: { proposals: 10 },
  Premium: { proposals: Infinity },
};

const ProtectedPublishButton = ({
  children,
  projectId,
  paidMembership,
  onMembershipClick,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handlePublishClick = () => {
    const membership = paidMembership?.data();

    if (!membership?.active) {
      setOpenModal(true);
      return;
    }

    const currentPlan = membership.planName;
    const proposalCount = membership.proposalCount || 0;
    const limit = PLAN_LIMITS[currentPlan]?.proposals;

    if (proposalCount >= limit) {
      setOpenModal(true);
      return;
    }

    navigate(`/publishProposal?projectId=${projectId}`);
    if (onMembershipClick) {
      onMembershipClick(paidMembership.ref, membership);
    }
  };

  const getModalMessage = () => {
    const membership = paidMembership?.data();

    if (!membership?.active) {
      return "Para publicar propuestas, necesitas tener una suscripción activa.";
    }

    return "Has alcanzado el límite de propuestas para tu plan actual.";
  };

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        onClick={handlePublishClick}
        className="w-full"
      >
        {children}
      </Button>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Suscripción Requerida</DialogTitle>
        <DialogContent>
          <Typography>{getModalMessage()}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} variant="outline">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              setOpenModal(false);
              navigate("/subscription");
            }}
          >
            Ver Planes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProtectedPublishButton;
