import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Button,
  Paper,
  Divider,
} from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import Header3 from "./Header3";
import BottomNav from "./BottomNav";

function ProjectProposal() {
  const { projectId, proposalId } = useParams();
  const [loading, setLoading] = useState(true);
  const [proposal, setProposal] = useState(null);
  const [project, setProject] = useState(null);
  const [freelancer, setFreelancer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectAndProposal = async () => {
      try {
        setLoading(true);
        
        // Fetch project details
        const projectDoc = await getDoc(doc(db, "projects", projectId));
        if (projectDoc.exists()) {
          setProject({ id: projectDoc.id, ...projectDoc.data() });
        } else {
          console.log("No such project!");
          return;
        }

        // Fetch the specific proposal
        const proposalDoc = await getDoc(doc(db, "projects", projectId, "proposals", proposalId));
        if (proposalDoc.exists()) {
          const proposalData = proposalDoc.data();
          setProposal({ id: proposalDoc.id, ...proposalData });

          // Fetch freelancer details
          const freelancerDoc = await getDoc(doc(db, "users", proposalData.freelanceUid));
          if (freelancerDoc.exists()) {
            setFreelancer({ id: freelancerDoc.id, ...freelancerDoc.data() });
          }
        } else {
          console.log("No such proposal!");
        }
      } catch (error) {
        console.error("Error fetching project and proposal:", error);
      } finally {
        setLoading(false);
      }
    };

    if (projectId && proposalId) {
      fetchProjectAndProposal();
    }
  }, [projectId, proposalId]);

  return (
    <Container maxWidth="md">
      <Header3 />
      <Box sx={{ mt: 14, mb: 20 }}>
        {loading ? (
          <Box sx={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {project && proposal && freelancer ? (
              <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h5" sx={{ color: "dodgerblue", mb: 2 }}>
                  Propuesta para: {project.title}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Freelancer:</strong> {freelancer.displayName}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Precio ofertado:</strong> ${proposal.price}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Presupuesto original:</strong> ${project.price}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  <strong>Descripción de la propuesta:</strong>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>
                  {proposal.description}
                </Typography>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => navigate(`/chatbox/${projectId}/${proposalId}/${freelancer.id}/${project.uid}`)}
                  sx={{ mt: 2 }}
                >
                  Chatear con el freelancer
                </Button>
              </Paper>
            ) : (
              <Typography>No se encontró la propuesta o el proyecto.</Typography>
            )}
            <Button
              variant="outlined"
              fullWidth
              onClick={() => navigate(`/home`)}
              sx={{ mt: 2 }}
            >
              Volver a inicio
            </Button>
          </>
        )}
      </Box>
      <BottomNav />
    </Container>
  );
}

export default ProjectProposal;