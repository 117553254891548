import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  limit,
} from "firebase/firestore";
import {
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  CircularProgress,
  Link
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { getSession } from "../session";
import Header3 from "./Header3";

function ChatBox() {
  const { projectId, proposalId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const [projectTitle, setProjectTitle] = useState("");

  const fetchAuthUserAndMessages = useCallback(async () => {
    try {
      const user = await getSession();
      if (!user) {
        throw new Error("No authenticated user found.");
      }

      const userDocRef = doc(db, "users", user.uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        setAuthUser({
          uid: user.uid,
          displayName: userData.displayName || user.email,
          email: user.email,
        });

        if (!projectId || !proposalId) {
          throw new Error("Project ID or Proposal ID is missing");
        }

        // Fetch project title
        const projectDocRef = doc(db, "projects", projectId);
        const projectDocSnapshot = await getDoc(projectDocRef);
        if (projectDocSnapshot.exists()) {
          setProjectTitle(projectDocSnapshot.data().title);
        }

        const messagesPath = `projects/${projectId}/proposals/${proposalId}/messages`;
        const messagesRef = collection(db, messagesPath);
        const q = query(messagesRef, orderBy("timestamp", "desc"), limit(50));

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const newMessages = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .reverse();
          setMessages(newMessages);
          scrollToBottom();
        });

        return () => unsubscribe();
      } else {
        throw new Error("User document not found in Firestore");
      }
    } catch (error) {
      console.error("Error in fetchAuthUserAndMessages:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [projectId, proposalId]);

  useEffect(() => {
    fetchAuthUserAndMessages();
  }, [fetchAuthUserAndMessages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" || !authUser || !projectId || !proposalId) {
      return;
    }

    const message = {
      content: newMessage.trim(),
      timestamp: new Date(),
      senderId: authUser.uid,
      senderDisplayName: authUser.displayName,
      read: false,
    };

    const messagesPath = `projects/${projectId}/proposals/${proposalId}/messages`;

    try {
      const messagesRef = collection(db, messagesPath);
      await addDoc(messagesRef, message);
      setNewMessage("");
      scrollToBottom();
    } catch (error) {
      console.error("Error sending message: ", error);
      setError("Failed to send message. Please try again.");
    }
  };

  const linkify = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => 
      urlRegex.test(part) ? (
        <Link key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </Link>
      ) : (
        part
      )
    );
  };

  const markMessageAsRead = useCallback(
    async (messageId) => {
      if (!projectId || !proposalId) return;

      const messageRef = doc(
        db,
        `projects/${projectId}/proposals/${proposalId}/messages`,
        messageId
      );
      try {
        await updateDoc(messageRef, { read: true });
      } catch (error) {
        console.error("Error marking message as read: ", error);
      }
    },
    [projectId, proposalId]
  );

  useEffect(() => {
    if (authUser) {
      messages.forEach((message) => {
        if (message.senderId !== authUser.uid && !message.read) {
          markMessageAsRead(message.id);
        }
      });
    }
  }, [messages, authUser, markMessageAsRead]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: "slateblue",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header3 />
      <Typography
        variant="h5"
        gutterBottom
        sx={{ color: "white", textAlign: "center", mt: 2 }}
      >
        Chat: {projectTitle}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {messages.map((message) => (
          <Box
            key={message.id}
            sx={{
              alignSelf:
                message.senderId === authUser.uid ? "flex-end" : "flex-start",
              maxWidth: "70%",
              mb: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                bgcolor:
                  message.senderId === authUser.uid ? "#CAEDED" : "#FFF8E1",
                p: 2,
                borderRadius: "10px",
                color: "black",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  color:
                    message.senderId === authUser.uid
                      ? "dodgerblue"
                      : "mediumseagreen",
                }}
              >
                {message.senderDisplayName}
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                {linkify(message.content)}
              </Typography>
            </Typography>
            {message.senderId === authUser.uid && (
              <Typography variant="caption" sx={{ color: "white", mt: 0.5 }}>
                {message.read ? "Leído" : "Enviado"}
              </Typography>
            )}
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>
      <Box
        sx={{
          p: 2,
          backgroundColor: "white",
        }}
      >
        <TextField
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Escribe tu mensaje..."
          variant="outlined"
          fullWidth
          multiline
          maxRows={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendMessage}
                  disabled={!newMessage.trim()}
                >
                  <SendIcon />
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

export default ChatBox;
