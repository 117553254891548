import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getAuth,
  setPersistence,
  browserLocalPersistence,
  onAuthStateChanged,
  getIdToken,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

// Configuración e inicialización de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA9xuGDdfHvfBOnox4Gq627WfiNU6akQiU",
  authDomain: "skach-931b7.firebaseapp.com",
  databaseURL: "https://skach-931b7-default-rtdb.firebaseio.com",
  projectId: "skach-931b7",
  storageBucket: "skach-931b7.appspot.com",
  messagingSenderId: "756758090081",
  appId: "1:756758090081:web:b686b9984319496bdb7520"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export { app, db, auth, storage, messaging, analytics };


export async function saveTokenToFirestore(token) {
  const userId = auth.currentUser ? auth.currentUser.uid : null;

  if (!userId) {
    console.error("Usuario no autenticado");
    return;
  }

  const userRef = doc(db, "users", userId);

  try {
    await setDoc(userRef, { fcmToken: token }, { merge: true });
    console.log("Token FCM guardado en Firestore con éxito");
  } catch (error) {
    console.error("Error al guardar el token FCM en Firestore:", error);
  }
}

// Funciones adicionales relacionadas con la autenticación y la gestión de usuarios

export const getToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      if (user) {
        getIdToken(user).then(
          (idToken) => {
            resolve(idToken);
          },
          (error) => {
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
  });
};

export const token = async () => {
  try {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const idToken = await currentUser.getIdToken();
      return idToken;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error retrieving ID token:", error);
    return null;
  }
};

export const signInUser = (email, password) => {
  return setPersistence(auth, browserLocalPersistence)
    .then(() => {
      return signInWithEmailAndPassword(auth, email, password);
    })
    .catch((error) => {
      console.error(error.code, error.message);
      throw error;
    });
};

export const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const userRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      await setDoc(userRef, {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || "",
        phone: "",
        point: "",
        profileImageUrl: user.photoURL || "https://firebasestorage.googleapis.com/v0/b/notiline-vtoisn.appspot.com/o/user.png?alt=media&token=470b8374-53a9-40c6-8013-68a466566fef",
      });
      return {
        user,
        isNewUser: true
      };
    }
    return {
      user,
      isNewUser: false
    };
  } catch (error) {
    console.error("Error en la autenticación con Google:", error);
    throw error;
  }
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log(
      "¡Correo de restablecimiento de contraseña enviado correctamente!"
    );
  } catch (error) {
    throw new Error(
      "Error al enviar el correo de restablecimiento: " + error.message
    );
  }
};

export const createUser = async (
  email,
  password,
  displayName,
  phone,
  point
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const user = userCredential.user;
    const uid = user.uid;

    const userRef = doc(db, "users", uid);
    await setDoc(userRef, {
      uid,
      email,
      displayName,
      phone,
      point,
      profileImageUrl:
        "https://firebasestorage.googleapis.com/v0/b/notiline-vtoisn.appspot.com/o/user.png?alt=media&token=470b8374-53a9-40c6-8013-68a466566fef",
    });

    return userCredential;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (uid, displayName, phone, point) => {
  try {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, {
      displayName,
      phone,
      point,
    });
    const userData = await getDoc(userRef);
    if (userData.exists()) {
      return { uid, ...userData.data() };
    } else {
      throw new Error("El usuario no existe");
    }
  } catch (error) {
    throw error;
  }
};

export const uploadImage = async (file) => {
  try {
    const randomId = Math.random().toString(36).substr(2, 9);
    const storageRef = ref(storage, `images/${randomId}-${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    throw error;
  }
};

export const updateUserProfile = async (uid, profileData) => {
  try {
    const userRef = doc(db, "users", uid);
    await updateDoc(userRef, profileData);
    console.log("Perfil del usuario actualizado correctamente.");
  } catch (error) {
    console.error("Error al actualizar el perfil del usuario:", error);
    throw error;
  }
};