import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CardMedia,
  Container,
  Link,
  TextField,
  Typography,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { createUser } from "../firebase";
import logoSvg from "../imgs/logo.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [point, setPoint] = useState("");
  const [skill, setSkill] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [userType, setUserType] = useState("cliente");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const register = async () => {
    try {
      if (password !== confirmPassword) {
        throw new Error("Las contraseñas no coinciden.");
      }

      await createUser(
        email,
        password,
        displayName,
        phone,
        point,
        userType === "freelancer" ? skill : null,
        userType
      );

      // Limpiar el formulario después de un registro exitoso
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setPhone("");
      setPoint("");
      setSkill("");
      setUserType("cliente");
      setError("");
      navigate("/home");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChangeUserType = (event) => {
    setUserType(event.target.value);
    if (event.target.value === "cliente") {
      setSkill(""); // Limpiar la habilidad si cambia a cliente
    }
  };

  const styles = {
    media: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "90px",
      height: "auto",
      paddingTop: "13%", // 16:9,
      marginTop: "40px",
      marginBottom: "30px",
    },
    register: {
      fontWeight: "300",
      fontSize: "18px",
      marginTop: "-10px",
    },
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 1 }}>
      <CardMedia
        component="img"
        image={logoSvg} // Utilizar la variable que contiene la imagen SVG
        title="Skach"
        style={styles.media} // Especificar estilos si es necesario
      />
      <Typography
        variant="h5"
        style={styles.register}
        component="h1"
        gutterBottom
        textAlign="center"
      >
        Formulario de registro
      </Typography>
      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      )}
      <Box component="form">
        <Container maxWidth="sm">
          <TextField
            label="Correo Electrónico"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Contraseña"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            sx={{ mt: 3 }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <TextField
            label="Repetir Contraseña"
            fullWidth
            type={showPassword ? "text" : "password"}
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Typography>Seleccione el tipo de usuario:</Typography>
          <Select
            sx={{ mt: 1.5, marginBottom: 1 }}
            value={userType}
            onChange={handleChangeUserType}
            fullWidth
            margin="normal"
            label="Tipo de Usuario"
          >
            <MenuItem value="cliente">Cliente</MenuItem>
            <MenuItem value="freelancer">Freelancer</MenuItem>
          </Select>
          <TextField
            label={
              userType === "freelancer"
                ? "Nombre de Freelancer"
                : "Nombre de Cliente"
            }
            fullWidth
            margin="normal"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <TextField
            label="Número de Teléfono"
            fullWidth
            margin="normal"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            label="Punto (Localidad)"
            fullWidth
            margin="normal"
            value={point}
            onChange={(e) => setPoint(e.target.value)}
          />
          {userType === "freelancer" && (
            <>
              <Typography>Seleccione una habilidad:</Typography>
              <Select
                sx={{ mt: 1.5, marginBottom: 1 }}
                value={skill}
                onChange={(e) => setSkill(e.target.value)}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Selecciona una habilidad
                </MenuItem>
                <MenuItem value="Diseño Web">Diseño Web</MenuItem>
                <MenuItem value="Diseño UX/UI">Diseño UX/UI</MenuItem>
                <MenuItem value="Diseño Grafico">Diseño Gráfico</MenuItem>
                <MenuItem value="Programación">Programación</MenuItem>
                <MenuItem value="Desarrollo de Apps">
                  Desarrollo de Apps
                </MenuItem>
                <MenuItem value="Community Manager">Community Manager</MenuItem>
                <MenuItem value="Marketing Digital">Marketing Digital</MenuItem>
                <MenuItem value="Diseño Multimedia">Diseño Multimedia</MenuItem>
                <MenuItem value="Edición de Videos">Edición de Videos</MenuItem>
                <MenuItem value="Edición de Imágenes">
                  Edición de Imágenes
                </MenuItem>
                <MenuItem value="Redacción de Contenidos">
                  Redacción de Contenidos
                </MenuItem>
                <MenuItem value="Traducción">Traducción</MenuItem>
              </Select>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={register}
            sx={{ mt: 3 }}
            fullWidth
          >
            Registrarse
          </Button>
        </Container>
        <Box textAlign="center" sx={{ p: 5, mt: 2 }}>
          ¿Ya tienes cuenta? <Link href="/login">Iniciar sesión</Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
