import React, { useEffect, useState, useCallback } from "react";
import Header3 from "./Header3";
import BottomNav from "./BottomNav";
import {
  Container,
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebase";

function Project() {
  const { projectId } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [project, setProject] = useState(null);
  const [projectIcon, setProjectIcon] = useState(null);
  const [showDialogCC, setShowDialogCC] = useState(false);

  useEffect(() => {
    const fetchMembership = async () => {
      try {
        const { uid } = auth.currentUser || {};
        if (uid) {
          const userDocRef = doc(db, "users", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            if (userData.userType === "freelancer") {
              const membershipRef = collection(userDocRef, "memberships");
              const membershipSnapshot = await getDocs(membershipRef);
              if (membershipSnapshot.empty) {
                setShowDialogCC(true);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching memberships:", error);
      }
    };

    fetchMembership();
  }, []);

  const getIconForUserType = useCallback(async () => {
    try {
      const { uid } = auth.currentUser || {};
      if (!uid) {
        return null;
      }

      const userDocRef = doc(db, "users", uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const membershipsRef = collection(userDocRef, "memberships");
        const membershipsSnapshot = await getDocs(membershipsRef);

        const paidMembership = membershipsSnapshot.docs.find(
          (doc) => doc.data().paymentStatus === "pagado"
        );

        if (paidMembership) {
          return (
            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              onClick={() => {
                navigate(`/publishProposal?proyectId=${projectId}`);
                handleClick(paidMembership.ref, paidMembership.data());
              }}
            >
              Enviar una propuesta
            </Button>
          );
        } else {
          return (
            <Button
              variant="outlined"
              sx={{ mt: 2 }}
              fullWidth
              disabled
              href="/membership"
            >
              Hazte miembro y envía una propuesta
            </Button>
          );
        }
      }
      return null;
    } catch (error) {
      console.error("Error fetching icon:", error.message);
      return null;
    }
  }, [navigate, projectId]);

  const handleClick = async (docRef, membershipData) => {
    try {
      await updateDoc(docRef, {
        clicks: membershipData.clicks
          ? String(Number(membershipData.clicks) + 1)
          : "1",
      });
    } catch (error) {
      console.error("Error updating clicks:", error.message);
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        setLoading(true);
        const projectDocRef = doc(db, "projects", projectId);
        const projectSnapshot = await getDoc(projectDocRef);
        
        if (projectSnapshot.exists()) {
          const projectData = { id: projectSnapshot.id, ...projectSnapshot.data() };
          setProject(projectData);
          const icon = await getIconForUserType();
          setProjectIcon(icon);
        } else {
          console.log("No such project!");
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      } finally {
        setLoading(false);
      }
    };

    if (projectId) {
      fetchProject();
    }
  }, [projectId, getIconForUserType]);

  const formatDate = (timeToDo) => {
    if (timeToDo instanceof Date) {
      return timeToDo.toLocaleDateString();
    } else if (timeToDo && timeToDo.toDate instanceof Function) {
      return timeToDo.toDate().toLocaleDateString();
    }
    return "";
  };

  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      padding: "24px",
      width: "87%",
      border: "1px solid black",
      borderRadius: "10px",
    },
    title: {
      fontSize: "24px",
      color: "dodgerblue",
      marginBottom: "7px",
    },
    description: {
      fontSize: "18px",
      marginBottom: "7px",
    },
    price: {
      color: "lightseagreen",
      marginBottom: "7px",
    },
    skills: {
      marginBottom: "7px",
    },
    timeToDo: {
      color: "lightseagreen",
      fontSize: "15px",
      marginBottom: "7px",
    },
    created: {
      fontSize: "10px",
      marginBottom: "7px",
    },
  };

  return (
    <Container maxWidth="md">
      <Header3 />
      <Dialog open={showDialogCC} onClose={() => setShowDialogCC(false)}>
        <DialogTitle>¡Hazte miembro de la red!</DialogTitle>
        <DialogContent>
          <Button href="/membership" fullWidth variant="outlined">
            Obtener Membresia
          </Button>
        </DialogContent>
      </Dialog>
      <Box sx={{ mt: 14, mb: 20 }}>
        {loading ? (
          <Box sx={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </Box>
        ) : project ? (
          <Box sx={styles.card}>
            <Typography sx={styles.title}>{project.title}</Typography>
            <Typography sx={styles.description}>{project.description}</Typography>
            <Typography sx={styles.price}>Presupuesto: $ {project.price}</Typography>
            <Typography sx={styles.skills}>Habilidad: {project.skills.join(", ")}</Typography>
            <Typography sx={styles.timeToDo}>
              Fecha de entrega: {formatDate(project.timeToDo)}
            </Typography>
            <Typography sx={styles.created}>
              Fecha de publicación: {project.createdAt.toDate().toLocaleDateString()}
            </Typography>
            <Box sx={{ width: "100%" }}>{projectIcon}</Box>
          </Box>
        ) : (
          <Typography>No se encontró el proyecto.</Typography>
        )}
      </Box>
      <BottomNav />
    </Container>
  );
}

export default Project;