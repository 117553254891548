import { saveTokenToFirestore } from "../firebase";
import { getToken } from "firebase/messaging";

export const requestNotificationPermission = async (messaging) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BAmNUauE6rxFl4I56D8BGODFiMNOmRZw9CNthHc5_uCNBt8uWxi9s5-JYk6k3LzT1RHJGAXxmIqj34c1Kr3EoTA",
        });
        console.log("FCM token:", token);
  
        if (token) {
          await saveTokenToFirestore(token);
        } else {
          console.error("No se pudo obtener el token FCM");
        }
      } else {
        console.error("Permiso de notificaciones denegado");
      }
    } catch (error) {
      console.error("Error al obtener el token FCM:", error);
    }
  };